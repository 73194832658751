import * as React from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ButtonPrimary from "../components/Button";
import { Link } from "gatsby";

const NotFoundPage = () => {
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <main className="bg-black">
      <title>404 | Ksatra SEM - Fullstack designer freelance à Toulouse</title>

      {!isLoading && <Navbar bgColor="bg-black" textColor="text-white" />}

      <div className="mx-6 mt-24 mb-64 text-white lg:mx-56">
        <h1 className="mb-16 font-serif text-6xl">404</h1>
        <h2 className="mb-16 text-2xl">Une erreur s'est produite.</h2>
        <Link to="/">
          <ButtonPrimary title="Aller à la page d'accueil" />
        </Link>
      </div>

      <Footer />
    </main>
  )
};

export default NotFoundPage;
